import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.scss';
import IphoneFrame from 'images/iphone_frame_new.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function ImageSlider({ imageList }) {
  const settings = {
    fade: true,
    dots: true,
    dotsClass: 'slick-dots career-slick-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  // ******************************************
  return (
    <div
      className="career_mobile"
      style={{
        width: 224,
        height: 364,
        marginTop: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          width: 228,
          height: 368,
          backgroundImage: `url(${IphoneFrame})`,
          backgroundSize: 'cover',
          position: 'absolute',
          zIndex: 2,
          top: -4,
        }}
      ></div>
      <Slider {...settings}>
        {imageList.map((item, index) => (
          <div className="!w-200 h-364 overflow-hidden" key={index}>
            <img
              src={item.src}
              alt={item.alt}
              style={{
                borderRadius: 30,
                boxShadow: '0px 24px 24px 0px #2E34484D',
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export { ImageSlider };
