import React, { useContext, useEffect, useState } from 'react';
import './footer.scss';
import { useLocation, Link } from 'react-router-dom';
import { CommonContext } from '../../contexts/CommonContext';
import Modal from 'components/modal/Modal';
import ContactUs from 'components/modals/ContactUsPopup';
import {
  ModalDispatchContext,
  ModalStateContext,
} from '../../components/modal/ModalContext';
import MainLoadingPopups from '../../components/modals/MainLoadingPopups';
import { Divider } from '@mui/material';
import RightArrow from '../../images/rightArrow_white.svg';
import { appStoreClick } from '../../utils/common';

function Footer() {
  let location = useLocation();
  const [urlTitle, setUrlTitle] = useState('');
  useEffect(() => {
    setUrlTitle(location.pathname);
    window.aaa = location.pathname;
  }, [location, urlTitle]);

  const { open, termsOfService, privacyPolicy } =
    useContext(ModalDispatchContext);
  const openModal = (e) => {
    open(e.target.id);
  };
  const { state } = useContext(ModalStateContext);
  const { lastYear } = useContext(CommonContext);
  const handleTraineeClick = () => {
    window.location.href = '/trainee';
  };
  // **********************************************************************************
  return (
    <section
      id="footer"
      className="sm:flex sm:flex-row-reverse sm:!px-90 sm:!pt-70 sm:!pb-80 sm:justify-between"
    >
      <div className="w-full flex flex-col gap-16 sm:min-w-260 sm:max-w-260">
        <div className="h-32 flex justify-between sm:mb-24">
          <ul className="flex gap-20 text-white opacity-60 text-14 font-normal leading-25">
            <li onClick={openModal} id="termsModal">
              이용약관
            </li>
            <li onClick={openModal} id="policyModal">
              개인정보 처리방침
            </li>
          </ul>
          <a
            href="https://www.instagram.com/medistaff_inc?igsh=bWlhZGw3bmFhMnNv"
            target="_blank"
            className="!h-32"
          >
            <div className="bg_instagram w-32 h-32" />
          </a>
        </div>
        <Divider sx={{ borderColor: '#FFFFFF14' }} className="sm:hidden" />

        <div className="flex flex-col sm:justify-start items-start sm:items-end">
          <button
            className="h-36 text-white text-14 font-bold leading-20"
            style={{ letterSpacing: '0px' }}
            onClick={openModal}
            id="contactUsModal"
          >
            CONTACT US
          </button>
          <button
            className="h-36 text-white text-14 font-bold leading-20 opacity-60"
            style={{ letterSpacing: '0px' }}
            onClick={() => window.open('https://pco.medistaff.co.kr')}
          >
            학술대회 문의하기
          </button>
          <button
            className="h-36 text-white text-14 font-bold leading-20 opacity-60"
            style={{ letterSpacing: '0px' }}
            onClick={() =>
              window.open('https://pco.medistaff.co.kr/medistaff/calendar')
            }
          >
            강좌 등록하기
          </button>
          <button
            className="h-36 text-white text-14 font-bold leading-20 opacity-60"
            style={{ letterSpacing: '0px' }}
            onClick={() => handleTraineeClick()}
          >
            {lastYear} 전국 전공의 병원평가
          </button>
          <Divider sx={{ borderColor: '#FFFFFF14' }} />
        </div>
      </div>
      <div className="mt-24 opacity-60 flex flex-col gap-20 pt-20 sm:w-full sm:mt-0 sm:pt-0 sm:mr-40">
        <div className="hidden sm:flex gap-36">
          <Link to="/ourstory" className="text-14 font-bold leading-28 flex">
            Our story
          </Link>
          <Link to="/features" className="text-14 font-bold leading-28 flex">
            Features
          </Link>
          <Link to="/career" className="text-14 font-bold leading-28 flex">
            Career
          </Link>
          <Link to="/survey" className="text-14 font-bold leading-28 flex">
            Survey
          </Link>
          <Link to="/press" className="text-14 font-bold leading-28 flex">
            Press
          </Link>
          <Link to="/notice" className="text-14 font-bold leading-28 flex">
            Notice
          </Link>
          <Link to="/trainee" className="text-14 font-bold leading-28 flex">
            Trainee
          </Link>
        </div>
        <div className="flex flex-col sm:flex-row sm:mt-40">
          <div className="logo mb-20 sm:mb-0 sm:mr-40">
            <img
              src={require('../../images/icons/medistaff_icon.svg').default}
              alt="메디스태프 아이콘"
              className="sm:w-140 h-30"
            />
          </div>
          <div className="flex flex-col gap-32 sm:gap-24">
            <div className="flex flex-col gap-4 text-12 font-normal">
              <p className="address">상호명. 메디스태프</p>
              <p className="address">
                서울 오피스. 서울특별시 강남구 테헤란로 123 여삼빌딩 6층 621호
                (메디스태프)
              </p>
              <p className="address">
                본사. 부산광역시 수영구 광안로62번길 3, 105호(광안동, 이화)
              </p>
              <p className="address">이메일. support@medistaff.co.kr</p>
              <p className="address">대표전화. 02-6952-3889</p>
              <p className="address">대표자명. 기동훈</p>
              <p>
                사업자등록번호. 516-81-00372
                <br />
                통신판매업 신고번호. 제2018-서울강남-00833호
                <br />
                직업정보제공사업 신고번호. J1516020200005
              </p>
              <div className="!opacity-30 text-11 mt-4">
                © 2018 (주) 메디스태프. ALL RIGHTS RESERVED
              </div>
            </div>
            <div className="flex w-full gap-10">
              <div
                className="flex justify-center items-center py-14 w-full sm:w-130 sm:h-40"
                style={{ border: '1px solid #FFFFFF3D' }}
              >
                <img
                  className="w-90 h-20"
                  onClick={() => appStoreClick('aos')}
                  src={
                    require('../../images/icons/google_play_icon.svg').default
                  }
                  alt="구글 앱스토어 아이콘"
                />
              </div>
              <div
                className="flex justify-center items-center py-14 w-full sm:w-130 sm:h-40"
                style={{ border: '1px solid #FFFFFF3D' }}
              >
                <img
                  className="w-83 h-22"
                  onClick={() => appStoreClick('ios')}
                  src={
                    require('../../images/icons/ios_app_store_icon.svg').default
                  }
                  alt="애플 앱스토어 아이콘"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 이용약관, 개인정보 처리방침 팝업 */}
      <article>
        <Modal
          id="termsModal"
          openedModals={state.openedModals}
          header="Terms Of Service"
          zIndexStyle={2}
        >
          {termsOfService
            ? termsOfService
            : '해당 정보를 가져 올 수 없습니다. 관리자에게 문의하여주세요.'}
        </Modal>

        <Modal
          id="policyModal"
          openedModals={state.openedModals}
          header="Privacy Policy"
          zIndexStyle={2}
        >
          {privacyPolicy
            ? privacyPolicy
            : '해당 정보를 가져 올 수 없습니다. 관리자에게 문의하여주세요.'}
        </Modal>

        <Modal
          id="contactUsModal"
          openedModals={state.openedModals}
          header="Contact us"
        >
          <ContactUs />
        </Modal>
      </article>

      <MainLoadingPopups />
    </section>
  );
}
export default Footer;
